<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />

    <b-card>
      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label for="date_from">Date From</label>
            <b-input
              id="date_from"
              v-model="filter.date_from"
              type="date"
              @change="onFilterChanged"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label for="date_to">Date To</label>
            <b-input
              id="date_to"
              v-model="filter.date_to"
              type="date"
              @change="onFilterChanged"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <div class="form-group">
            <label>Filter By Status</label>
            <b-select
              v-model="filter.status"
              :options="filterStatuses"
              @change="onFilterChanged"
            />
          </div>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Search String</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button
            class="mr-1"
            variant="success"
            @click="onShowImportDataForm"
          >
            Import Data
          </b-button>
        </b-col>
      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :per-page="tableSettings.perPage"
        :current-page="tableSettings.currentPage"
        :items="myProvider"
        :fields="tableSettings.fields"
        :sort-by.sync="tableSettings.sortBy"
        :sort-desc.sync="tableSettings.sortDesc"
        :sort-direction="tableSettings.sortDirection"
        :filter="tableSettings.filter"
        :filter-included-fields="tableSettings.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ tableSettings.currentPage * tableSettings.perPage - tableSettings.perPage + (data.index + 1) }}
        </template>

        <template #cell(active)="data">
          {{ data.item.active == 1 ? "Yes" : "No" }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(actions)="row">
          <div class="text-nowrap">
            <b-button
              :disabled="row.item.status !== 'Processing'"
              size="sm"
              @click="onShowEditForm(row.item)"
            >
              Update
            </b-button>
          </div>
        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings.perPage"
              :options="tableSettings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings.currentPage"
            :total-rows="tableSettings.totalRows"
            :per-page="tableSettings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-import-data"
      scrollable
      no-close-on-backdrop
      title="Import Data"
      @ok="onConfirmAction"
    >
      <ValidationObserver ref="form">
        <form
          role="form"
          novalifilter_
        >
          <div class="row">
            <div class="col-12">
              <ValidationProvider
                #default="{ errors }"
                name="call date"
                vid="called_at"
                rules="required"
              >
                <b-form-group>
                  <label for="called_at">Call Date</label>
                  <b-input
                    id="called_at"
                    v-model="excelData.called_at"
                    type="date"
                    :state="errors.length > 0 ? false : null"
                    placeholder="enter call date"
                    autocomplete="off"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </div>

            <div class="col-12">
              <ValidationProvider
                #default="{ errors }"
                name="promotion"
                vid="promotion"
                rules="required"
              >
                <b-form-group>
                  <label for="promotion">Promotion</label>
                  <v-select
                    id="promotion"
                    v-model="selected.promotion"
                    :options="list.promotions"
                    :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                    label="promotion_name"
                  >
                    <template #option="{ promotion_name }">
                      <strong>{{ promotion_name }}</strong>
                    </template>
                    <template #no-options="">
                      No Available Promotions
                    </template>
                  </v-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </div>

            <div class="col-12">
              <ValidationProvider
                #default="{ errors }"
                name="sms type"
                vid="sms_type"
                rules="required"
              >
                <b-form-group>
                  <label for="sms_type">SMS Type</label>
                  <b-form-select
                    id="sms_type"
                    v-model="excelData.sms_type"
                    :options="options.sms_types"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  >
                    <template #first>
                      <b-form-select-option
                        :value="null"
                        disabled
                      >
                        -- Please Select Import Type --
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </div>

            <div class="col-12">
              <ValidationProvider
                #default="{ errors }"
                name="file"
                vid="file"
                rules="required"
              >
                <b-form-group>
                  <label for="file">Attach File: </label>
                  <b-form-file
                    v-model="excelData.file"
                    :class="`form-control ${errors.length > 0 ? 'is-invalid' : ''}`"
                    accept=".xlsx"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </div>
          </div>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          :disabled="(state.busy || excelData.file === null || list.invalidSmsLog.length>0 || state.uploading)"
          @click="ok()"
        >
          Upload
        </b-button>
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>

    <!-- modal -->
    <b-modal
      id="modal-invalid-excel-data"
      title="Invalid Data"
      size="xl"
      scrollable
      no-close-on-backdrop
    >
      <b-table
        ref="tableInvalidData"
        hover
        responsive
        :per-page="tableInvalidData.perPage"
        :current-page="tableInvalidData.currentPage"
        :items="list.invalidSmsLog"
        :fields="tableInvalidData.fields"
        :sort-by.sync="tableInvalidData.sortBy"
        :sort-desc.sync="tableInvalidData.sortDesc"
        :sort-direction="tableInvalidData.sortDirection"
        :filter="tableInvalidData.filter"
        :filter-included-fields="tableInvalidData.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ tableInvalidData.currentPage * tableInvalidData.perPage - tableInvalidData.perPage + (data.index + 1) }}
        </template>

        <template #cell(line_error)="data">
          <div class="text-nowrap text-danger">
            {{ data.item.line_error }}
          </div>
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>
      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableInvalidData.perPage"
              :options="tableInvalidData.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableInvalidData.currentPage"
            :total-rows="tableInvalidData.totalRows"
            :per-page="tableInvalidData.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>

      <template #modal-footer="{ cancel }">
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-edit-form"
      scrollable
      no-close-on-backdrop
      title="Update Log"
      @ok="onConfirmAction"
    >

      <ValidationObserver ref="form">
        <form
          role="form"
          novalidate
        >
          <b-form-group>
            <label for="customer_name"><strong>Customer Name</strong></label>
            <b-input
              id="customer_name"
              v-model="smsLog.customer_name"
              disabled
            />
          </b-form-group>

          <b-form-group>
            <label for="mobile_number"><strong>Mobile Number</strong></label>
            <b-input
              id="mobile_number"
              v-model="smsLog.mobile_number"
              disabled
            />
          </b-form-group>

          <b-form-group>
            <label for="promotion_name"><strong>Promotion Name</strong></label>
            <b-input
              id="promotion_name"
              v-model="smsLog.promotion_name"
              disabled
            />
          </b-form-group>

          <ValidationProvider
            #default="{ errors }"
            name="status"
            vid="status"
            rules="required"
          >
            <b-form-group>
              <label><strong>Status</strong></label>
              <b-select
                v-model="smsLog.status"
                :options="options.statuses"
                :state="errors.length > 0 ? false : null"
                :disabled="state.busy"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="sent at"
            vid="sent_at"
            :rules="rulesSentAt"
          >
            <b-form-group>
              <label for="sent_at"><strong>Sent At</strong></label>
              <b-input
                id="sent_at"
                v-model="smsLog.sent_at"
                type="text"
                :state="errors.length > 0 ? false : null"
                :disabled="state.busy || smsLog.status === 'Processing'"
                autocomplete="off"
                maxlength="20"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

        </form>
      </ValidationObserver>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          :disabled="state.busy"
          @click="ok()"
        >
          {{ state.editing ? "Update Record" : "Save Record" }}
        </b-button>
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import _ from 'lodash'
import { core } from '@/config/pluginInit'
import { SharedListService, AdminUtilitySMSService, AxiosError } from '@/services'
import readXlsxFile from 'read-excel-file'
import formatter from '@/mixins/formatter'

export default {
  name: 'AdminUtilityService',

  middleware: ['auth', 'admin'],

  metaInfo () {
    return {
      title: 'SMS'
    }
  },

  mixins: [formatter],

  data () {
    return {
      state: {
        busy: false,
        uploading: false,
        statusEditing: false
      },
      filter: {
        date_from: this.dateFormatter(Date.now()),
        date_to: this.dateFormatter(Date.now()),
        status: 'All'
      },
      selected: {
        promotion: null,
        status: null
      },
      list: {
        promotions: [],
        invalidSmsLog: []
      },
      smsLog: {
        customer_name: '',
        mobile_number: '',
        promotion_name: '',
        status: null
      },
      excelData: {
        file: null,
        called_at: null,
        sms_type: null,
        promotion: null,
        list: []
      },
      options: {
        statuses: [
          { text: 'Sent', value: 'Sent' },
          { text: 'Failed', value: 'Failed' },
          { text: 'Processing', value: 'Processing' }
        ],
        sms_types: [
          { text: 'Daily', value: 'Daily' },
          { text: 'Special', value: 'Special' }
        ]
      },
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'called_at', formatter: this.dateFormatter, sortable: true },
          { key: 'customer_name' },
          { key: 'mobile_number' },
          { key: 'promotion_name' },
          { key: 'reason' },
          { key: 'status' },
          { key: 'sent_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'updated_at', formatter: this.dateTimeFormatter, sortable: true }
        ]
      },
      tableInvalidData: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          { key: 'mobile_number' },
          { key: 'reason' },
          { key: 'status' },
          { key: 'line_number' },
          { key: 'line_error' }
        ]
      }
    }
  },

  computed: {
    modalTitle () {
      return this.state.editing ? 'Update Raw Data' : 'Add Raw Data'
    },

    filterStatuses () {
      return [{ value: 'All', text: 'All' }].concat(this.options.statuses)
    },

    rulesSentAt () {
      if (this.smsLog.status === 'Sent' || this.smsLog.status === 'Failed') {
        return 'required|min:20|max:20'
      }

      return ''
    }
  },

  watch: {
    'excelData.file' (value) {
      if (value) {
        this.onParseExcelFile(value)
      }
    },

    'list.invalidSmsLog' (value) {
      this.tableInvalidData.totalRows = value?.length || 0
    },

    'selected.promotion' (promotion) {
      this.excelData.promotion = promotion?.id || null
    },

    'smsLog.status' (value) {
      if (value === 'Processing') {
        this.smsLog.sent_at = ''
      }
    }
  },

  mounted () {
    core.index()
    this.getPromotions()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await AdminUtilitySMSService.get(
          this.objectToUrl({
            page: ctx.currentPage,
            per_page: ctx.perPage,
            sort: ctx.sortBy,
            sort_desc: ctx.sortDesc,
            filter_from: this.filter.date_from,
            filter_to: this.filter.date_to,
            filter_status: this.filter.status,
            filter_text: ctx.filter
          })
        )
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    async getPromotions () {
      await SharedListService.getPromotions().then(({ data }) => {
        this.list.promotions = data
      })
    },

    onShowImportDataForm () {
      this.state.editing = false
      this.excelData.called_at = null
      this.selected.promotion = null
      this.excelData.file = null
      this.excelData.sms_type = 'Daily'
      this.list.invalidSmsLog = []
      this.$bvModal.show('modal-import-data')
    },

    onShowEditForm (current) {
      if (current.status !== 'Processing') {
        return
      }

      this.state.editing = true
      this.smsLog.id = current.id
      this.smsLog.customer_name = current.customer_name
      this.smsLog.mobile_number = current.mobile_number
      this.smsLog.promotion_name = current.promotion_name
      this.smsLog.status = current.status
      this.smsLog.sent_at = ''

      this.$bvModal.show('modal-edit-form')
    },

    async onParseExcelFile (data) {
      await readXlsxFile(data).then(excelRow => {
        this.excelData.data = []

        if (excelRow.length <= 1) {
          return 'No entries found'
        }

        if (
          excelRow[0][0] !== 'mobile_number' ||
          excelRow[0][1] !== 'reason' ||
          excelRow[0][2] !== 'status'
        ) {
          return 'Column Titles must be (mobile_number, reason, status)'
        }

        if (excelRow.length >= 501) {
          return 'Entries must not exceed beyond 500'
        }

        // reset list
        this.list.invalidSmsLog = []
        this.excelData.list = []

        for (let i = 1; i <= excelRow.length - 1; i++) {
          let isOk = true
          let error = ''

          if (
            excelRow[i][0] === null ||
            excelRow[i][1] === null ||
            excelRow[i][2] === null
          ) {
            isOk = false
            error = 'row value cannot be empty.'
          }

          if (isOk) {
            if (String(excelRow[i][0]).length !== 11) {
              isOk = false
              error = 'Mobile number should be 11 characters.'
            }
          }

          if (isOk) {
            if (String(excelRow[i][1]).length > 500) {
              isOk = false
              error = 'Reason should contain maximum of 500 characters only.'
            }
          }

          if (isOk) {
            const status = ['Sent', 'Failed', 'Processing']

            if (!status.includes(String(excelRow[i][2]))) {
              isOk = false
              error = 'Status should be Sent, Failed or Processing only.'
            }
          }

          if (isOk) {
            this.excelData.list.push({
              mobile_number: excelRow[i][0],
              reason: excelRow[i][1],
              status: excelRow[i][2],
              line_number: i + 1,
              line_error: ''
            })
          } else {
            this.list.invalidSmsLog.push({
              mobile_number: excelRow[i][0],
              reason: excelRow[i][1],
              status: excelRow[i][2],
              line_number: i + 1,
              line_error: error
            })
          }
        }

        if (this.list.invalidSmsLog.length > 0) {
          this.$bvModal.show('modal-invalid-excel-data')
        }

        return null
      })
        .then(error => {
          if (error) {
            this.$refs.form.setErrors({
              file: [error]
            })
          }
        })
        .catch(() => {
          this.$refs.form.setErrors({
            file: ['There was a problem reading your uploaded excel file.']
          })
        })
    },

    async onConfirmAction (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.form.validate().then(async success => {
        if (success) {
          let title = 'Do you really want to upload sms data?'

          if (this.state.editing) {
            title = 'Do you really want to save changes?'
          }

          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: title,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              if (this.state.editing) {
                return this.onFormPutSubmit()
              } else {
                return this.onUploadFormPostSubmit()
              }
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            centered: true
          })
        }
      })
    },
    async onUploadFormPostSubmit () {
      return new Promise(resolve => {
        this.state.uploading = true
        AdminUtilitySMSService.post(this.excelData).then(({ data }) => {
          this.state.uploading = false
          this.$bvModal.hide('modal-import-data')
          this.list.invalidSmsLog = data.invalid_sms_log

          if (data.total_uploaded === data.total_valid) {
            this.$swal.fire({
              icon: 'success',
              title: 'Successful',
              text: data.message,
              confirmButtonColor: '#06C270',
              confirmButtonText: 'Dismiss'
            }).then(() => {
              this.$refs.table.refresh()
            })
          }

          if (data.total_uploaded === data.total_invalid) {
            this.$swal.fire({
              icon: 'warning',
              title: 'Warning',
              text: 'Oppss! All the SMS data you uploaded in invalid.',
              confirmButtonColor: '#06C270',
              confirmButtonText: 'Dismiss'
            }).then(() => {
              this.$bvModal.show('modal-invalid-excel-data')
            })
          }

          if (parseInt(data.total_valid) > 0 && parseInt(data.total_invalid) > 0) {
            this.$swal.fire({
              icon: 'warning',
              title: 'Warning',
              text: 'Oppss! Some of the SMS data you uploaded is invalid.',
              confirmButtonColor: '#06C270',
              confirmButtonText: 'Dismiss'
            }).then(() => {
              this.$refs.table.refresh()
              this.$bvModal.show('modal-invalid-excel-data')
            })
          }
        })
          .catch(error => {
            this.state.uploading = false
            if (error instanceof AxiosError) {
              if (error.code === 422) {
                this.$refs.form.setErrors(error.message)
                resolve(error)
              }
            }
          })
      })
    },

    async onFormPutSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        AdminUtilitySMSService.put(this.smsLog).then(({ data }) => {
          this.$bvModal.hide('modal-edit-form')
          this.state.busy = false
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            const row = _.find(this.$refs.table.localItems, { id: data.sms_log.id })
            row.active = data.sms_log.active
            row.customer_name = data.sms_log.customer_name
            row.mobile_number = data.sms_log.mobile_number
            row.promotion_name = data.sms_log.promotion_name
            row.status = data.sms_log.status
            row.sent_at = data.sms_log.sent_at
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
                title: 'Validation Error',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                centered: true
              })
              this.$refs.form.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    },

    onFilterChanged () {
      this.$refs.table.refresh()
    }
  }
}
</script>
